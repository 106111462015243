/* eslint-disable */

const initSiteModal = () => {
    let hostEl = document.querySelector('[data-syndicate]');
    let debug = hostEl.dataset.debug || !!( new URLSearchParams(window.location.search).get('__pp-debug') !== null );
    window.dataLayer = window.dataLayer || [];
    if( debug ) { console.log('starting initSiteModal()...') }
    defaults = {
      'endpoint'   : 'https://www.propublica.org/',
      'element'    : '#newsletter-roadblock',
      'stylesheet' : 'https://assets.propublica.org/static/v5/css/deploy/syndicated-newsletter.css',
      'frequency'  : 3,
      'delay'      : 0,
      'scroll'     : 'body',
      'hostCookie' : 'bigstory'
    }
    const funnel = window.PP.funnel;
    const newsletterList = "The Big Story";

    showLogic = function( hostCookie, frequency ) {
      // pageviews per session: ~ 2 - 2.5 -- Jan 2021

      let cookieVal = funnel.load_cookie();
      let signupStatus = funnel.is_signedup(newsletterList),
          sessionCount = funnel.get_session_count(),
          displayStatus = funnel.current_state.displayModal;

      if( debug ) {
        console.warn( "Modal syndication script is in debug mode." );

        console.log("current_state:");
        console.log(PP.funnel.current_state);

        return true;
      }

      if (signupStatus == 'true') {
        // used to be every 15 pageviews now every 6 sessions (12 - 15 pvs)
        frequency = frequency * 2;
      } else {
        // used to be every 9 pageviews now every 3 sessions (6 - 7.5)
        frequency = frequency;
      }

      if ( displayStatus &&
           ( sessionCount == 1 || sessionCount % frequency == 0 ) )
      {
        // recaptchaInit && recaptchaInit();
        return true;
      }
    },

    injectStylesheet = function( target, href ){
      var link = document.createElement( 'link' );

      link.setAttribute( 'rel', 'stylesheet' );
      link.setAttribute( 'type', 'text/css' );
      link.setAttribute( 'href', href );

      // Bail if this stylesheet is already `link`ed:
      for( l = target.document.styleSheets.length, i = 0; i < l; i++ ) {
        if( target.document.styleSheets[ i ].href == href ) {
          return;
        }
      }

      target.document.write( link.outerHTML );
    },
    injectScript = function( target, href ){
      var link = document.createElement( 'script' );

      link.setAttribute( 'src', href );

      // Bail if this stylesheet is already `link`ed:
      for( l = target.document.scripts.length, i = 0; i < l; i++ ) {
        if( target.document.scripts[ i ].src == href ) {
          return;
        }
      }

      target.document.write( link.outerHTML );
    },

    handleResult = function( data ) {
        let element    = hostEl.dataset.element || defaults.element,
        frequency  = hostEl.dataset.frequency || defaults.frequency,
        hostCookie = hostEl.dataset.cookie || defaults.hostCookie,
        newsModal = data.querySelector( element );
        injectEl = document.createElement('iframe');
        injectEl.className = 'syndicated-modal';
        injectEl.title = "Sign up for our newsletter?";
        injectEl.style.position = 'fixed';
        injectEl.style.left = 0;
        injectEl.style.right = 0;
        injectEl.style.top = 0;
        injectEl.style.bottom = 0;
        injectEl.style.zIndex = 1001;
        injectEl.style.width = '100%';
        injectEl.style.height = '100%';
        injectEl.setAttribute('data-pp-location', 'modal');
        injectEl.setAttribute('data-pp-category', 'newsletter_signup');
        injectEl.setAttribute('data-pp-view', '');
        let target = hostEl.dataset.target;
        let stylesheet = hostEl.dataset.stylesheet || defaults.stylesheet;
        let iframew = function( iframe ) {
          if ( iframe.contentWindow ) {
            iframew = iframe.contentWindow;
          } else {
            if ( iframe.contentDocument && iframe.contentDocument.document ) {
              iframew = iframe.contentDocument.document;
            } else {
              iframew = iframe.contentDocument;
            }
          }
          return iframew;
        },
        iframewin;

      if( target ) {
        let targetEl = newsModal.querySelector('[name="target"]');
        if( targetEl ) { targetEl.value = target; }
      }

      if( showLogic( hostCookie, frequency ) === true  && typeof newsModal !== 'undefined' ) {

        injectEl.appendChild( newsModal );
        hostEl.parentNode.insertBefore( injectEl, hostEl.nextSibling );

        newsModal.style.opacity = 0;
        newsModal.classList.add('collapsible-enhanced','collapsible-expanded');
        newsModal.classList.remove('collapsible-collapsed');

        iframewin = iframew( injectEl );

        iframewin.document.open();
        iframewin.document.write( newsModal.outerHTML );

        injectStylesheet( iframewin, stylesheet );

        injectScript( iframewin, "https://www.google.com/recaptcha/api.js?onload=grecaptchaLoaded&render=6LcC1roqAAAAAK9equRCqsCagWPYzf79SKrxGFGY" );
        injectScript( iframewin, "https://assets.propublica.org/static/prod/v5/js/deploy/recaptcha-init.js" );

        let modalForms = iframewin.document.body.children.item(0)
        handleForms( hostCookie, modalForms );

        iframewin.document.close();
      }
    },
    handleForms = function( hostCookie, modal ) {
      let hostedModal = document.querySelector( '.syndicated-modal' );

      modal.addEventListener( "click", ( e ) => {
        // I.e. if you click directly on the first child of the iframe body
        // (`modal`): on screen but outside of the actual modal box
        if( e.target === modal ) {
            hostedModal.parentNode.removeChild( hostedModal );
          }
          e.stopPropagation();
      });
      modal.querySelectorAll('.close-btn, .close-link').forEach(( el ) => {
        el.addEventListener( "click", ( e ) => {
          funnel.deactivate_modal();
          funnel.save_cookie();
          hostedModal.parentNode.removeChild( hostedModal );
          window.dataLayer.push({
           event: 'modal_close',
          });
          e.preventDefault();
        });
      });
      document.addEventListener( "keyup", ( e ) => {
        if (e.keyCode == 27) {
          funnel.deactivate_modal();
          funnel.save_cookie();
          hostedModal.parentNode.removeChild( hostedModal );
          window.dataLayer.push({
            event: 'modal_close',
          });
          e.preventDefault();
        }
      });

       // Ensure all links inside the modal target the topmost context.
       modal.querySelectorAll('a').forEach(( el ) => { el.setAttribute('target','_top'); });
       modal.querySelectorAll('.newsletter-ajax').forEach(( el ) => {
         el.addEventListener( "submit", ( e ) => {
           e.preventDefault();

           let form = event.target;
           let submit = form.querySelector('input[type="submit"]');
           let url = form.getAttribute('action');
           let data = new FormData(form);
           submit.setAttribute('disabled', true);

           const xhr = new XMLHttpRequest();
           xhr.open("POST", url, true);
           xhr.responseType = "json";
           xhr.setRequestHeader(
             "Content-type",
             "application/x-www-form-urlencoded"
           );
           xhr.onload = () => {
             if (xhr.status !== 200) {
               // non-HTTP error
               form.classList.remove('submitting');
               form.classList.add('error');

               submit.value = 'Check email address and try again';
               submit.setAttribute('disabled', false);
             } else {
               form.classList.remove('submitting', 'error');
               form.classList.add('success');

               window.dataLayer.push({
                event: 'modal_subscribe'
               });

               if (window.PARSELY && window.PARSELY.conversions) {
                 window.PARSELY.conversions.trackNewsletterSignup('Modal');
               }

               submit.value = "You’re signed up!";
               funnel.add_newsletter_signup(newsletterList);
               funnel.deactivate_modal();
               funnel.save_cookie();
               if( debug ) {
                 console.log("current_state:");
                 console.log(PP.funnel.current_state);
                 console.log("Signed up for:");
                 console.log(PP.funnel.get_newsletter_signups());
               }
               setTimeout(function() {
                 hostedModal.parentNode.removeChild( hostedModal );
               }, 2000);
             }
           };
           xhr.onerror = () => {
             // non-HTTP error
             form.classList.remove('submitting');
             form.classList.add('error');

             submit.value = 'Check email address and try again';
             submit.setAttribute('disabled', false);
           };
           xhr.send(new URLSearchParams(data).toString());

         });
       });
    };

    initModal = (( el ) => {
      let delay = el.dataset.delay || defaults.delay,
      scrollset = el.dataset.scroll,
      scroll = scrollset || defaults.scroll,
      endpoint = el.dataset.endpoint || defaults.endpoint,
      initializeModal = function() {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", endpoint, true);
        xhr.responseType = 'document';
        xhr.onload = () => {
          handleResult( xhr.response );
        };
        xhr.onerror = () => { if( debug ) { console.log('uh oh. ' + endpoint ); } };
        xhr.send();
      },
      scrollPercentage = function( ele ) {
        return ele.getBoundingClientRect().bottom - window.innerHeight;
      },
      fireModal;

      if( scrollset ) {
        let scrollElement = document.querySelector( scroll );
        fireModal = function( e ) {
          if( scroll === "true" || scrollPercentage( scrollElement ) <= 0 ) {
            window.requestAnimationFrame(function() {
              setTimeout( initializeModal.bind( elem ), delay * 1000 );
              document.removeEventListener( "scroll", fireModal );
            });
          }
        }
        let elem = scrollElement;
        document.addEventListener( "scroll", fireModal );
      } else {
        setTimeout( initializeModal(), delay * 1000 );
      }
    });
    initModal(hostEl);
};

if (document.readyState === "complete") {
    initSiteModal();
} else {
    window.addEventListener("load", initSiteModal);
}
