document.querySelectorAll("select.action").forEach((elem) => {
    const scheduleSelector = /** @type {HTMLSelectElement} */ (elem);
    scheduleSelector.addEventListener("change", () => {
        const scheduleUrl = scheduleSelector.selectedOptions[0]?.dataset.href;
        if (scheduleUrl) window.location.href = scheduleUrl;
    });
});

document.querySelectorAll(".employee-toggle").forEach((elem) => {
    elem.addEventListener("click", (ev) => {
        ev.preventDefault();
        const empTable = elem.parentElement?.previousElementSibling;
        if (!empTable) return;

        if (elem.classList.contains("less")) {
            elem.classList.replace("less", "more");
            elem.textContent = "- View fewer people";
            empTable.querySelectorAll(".employee-row").forEach((rowElem) => {
                rowElem.classList.remove("hide");
            });
        } else {
            elem.classList.remove("more");
            elem.classList.add("less");
            elem.textContent = "+ View more people";
            empTable
                .querySelectorAll(".employee-row:not(.shortlist)")
                .forEach((rowElem) => {
                    rowElem.classList.add("hide");
                });
        }
    });
});

document.querySelectorAll(".audit-toggle").forEach((elem) => {
    elem.addEventListener("click", (ev) => {
        ev.preventDefault();
        const auditBox = elem.parentElement?.previousElementSibling;
        if (!auditBox) return;

        if (elem.classList.contains("less")) {
            elem.classList.replace("less", "more");
            elem.textContent = "- Show fewer audits";
            auditBox.querySelectorAll(".audit-box").forEach((rowElem) => {
                rowElem.classList.remove("hide");
            });
        } else {
            elem.classList.replace("more","less");
            let auditEntitiesCount = elem.getAttribute("data-audit-entities-count");
            // @ts-ignore
            elem.textContent = `+ Show ${auditEntitiesCount-1} more audit${auditEntitiesCount>2 ? 's' : ''} for subsidiaries`;
            auditBox
                .querySelectorAll(".audit-box:not(.shortlist)")
                .forEach((rowElem) => {
                    rowElem.classList.add("hide");
                });
        }
    });
});
